@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.CookieBarContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  animation: fadeIn 1s;
  z-index: 1;
}

.CookieBarButtonContainer {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.CookieBarButton {
  background-color: orange;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  color: black;
  white-space: nowrap;
  margin: 3px 5px;
}

.CookieBarMoreInfo {
  white-space: nowrap;
  margin: 3px 5px;
}
