/*
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
*/

/*
@import url("https://use.typekit.net/uci1yuu.css");
*/

@value colors: "../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint, nicepink from colors;

body {
  position: absolute;
  inset: 0;
  display: block;
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  display: flex;
  height: 100vh;
  height: calc(100vh);
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: calc(100vw);
  width: calc(var(--vw, 1vw) * 100);
  box-sizing: border-box;
}

button {
  all: unset;
}

.AuthorTitle {
  letter-spacing: 3px;
}

.ContentText {
  font-size: 18px;
  overflow-wrap: break-word;
  overflow: hidden;
}

.ProfileLink {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #adabab /* #83dbd9 */;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: #adabab /* #83dbd9 */;
}
