@value colors: "../cssvariables/colors.css";
@value primary, primaryStrong, primarySoft, primaryHint, secondary, secondaryStrong, secondarySoft, secondaryHint, accent, accentStrong, accentSoft, accentHint, nicepink from colors;

.AuthorTitle {
  letter-spacing: 3px;
}

.ContentText {
  font-size: 18px;
  overflow-wrap: break-word;
  overflow: hidden;
}

.ProfileLink {
  text-decoration: none;
}

.LinkText:focus {
  font-weight: bold;
  box-sizing: border-box;
}
